import React from 'react';
import ImageUploader from 'react-images-upload';
import ImageCropper from './ImageCropper';
import BlockUi from '../../common/BlockUi';
import BrandImageApi from '../../../api/BrandImage';
import {Input, Label, Form} from "reactstrap";;

class UploadImage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageUri: '',
            imagePath: '',
            isLoading: false,
        }

        this.onDrop = this.onDrop.bind(this);
    }

    onDrop(images) {
        this.setState({isLoading: true})
        const brandImage = images.length > 0 ? images[0]: {};
        const imageData = {brandImage};

        BrandImageApi.upload(imageData).then(response => {
            const imagePath = response.data.imagePath || '';
            const imageUri = response.data.image;
            this.setState({imageUri, imagePath});
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            this.setState({isLoading: false})
        });
    }

    render() {
        return (
            <>
                <ImageUploader
                    withIcon = {true}
                    buttonText = 'Choose images'
                    onChange = {this.onDrop}
                    imgExtension = {['.jpg', '.jpeg', '.png']}
                    label="Max file size: 5mb, accepted:jpg|png"
                    maxFileSize = {5242880}
                    singleImage = {true}
                />

                <BlockUi className="" tag="div" blocking={this.state.isLoading} message={"Processing"}>
                    {this.state.imageUri && (<ImageCropper imageUri = {this.state.imageUri} imagePath={this.state.imagePath} brandId = {this.props.brandId}/>)}
                </BlockUi>
            </>
        )
    }
}

export default UploadImage;