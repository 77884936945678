import React from 'react';
import {Input} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import defaultProductImage from '../../../../assets/Default_Product_Image.png';
import PriceFormat from "../../../../utils/PriceFormat";

const CartItem = ({
                      id,
                      name,
                      message,
                      price,
                      cartQty,
                      productImagePath,
                      error,
                      availableQty,
                      handleChangeQty,
                      defaultImageUrl,
                      defaultImageId,
                      handleRemoveItem
                  }) => {

    const cartImagePath = productImagePath !== null ? productImagePath : defaultProductImage;
    const currentBrandImagePath = defaultImageUrl !== null ? defaultImageUrl : defaultProductImage;
    const changeQty = (event) => {
        let qty = parseInt(event.target.value);
        handleChangeQty(id, qty, defaultImageId)
    }

    const removeItem = () => {
        handleRemoveItem(id, defaultImageId);
    }

    return (
        <tr className={error === 1 ? 'error-item' : ''}>
            <th scope="row">
                <img src={cartImagePath} height="60" alt='product image'/>
            </th>
            <td>
                <a href={`product/${id}`} target="_blank">
                    <span>{name}</span><br/>
                </a>
                {error && <span className="text-danger">{message}</span>}
            </td>
            <td className="text-end"><img src={currentBrandImagePath} height="60" alt='product image'/><br/></td>
            <td className="text-end"><PriceFormat price={price}/></td>
            <td className="text-end">
                <span className="qty">
                    <Input type="number" defaultValue={cartQty} min={1} max={availableQty} onChange={changeQty}/>
                </span>
            </td>
            <td className="text-end"><PriceFormat price={cartQty * price}/></td>
            <td>
                <a size="xs" href="#" className="remove-item" onClick={removeItem}>
                    <FontAwesomeIcon icon="trash-alt"/>
                </a>
            </td>
        </tr>
    );
}

export default CartItem;