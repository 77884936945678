
import React, {useState, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import {connect} from 'react-redux';
import {primaryAction} from '../../../actions/common/PopupActions';
import BrandImageApi from '../../../api/BrandImage';
import BrandsApi from '../../../api/Brands';
import {Input, Label} from "reactstrap";

const ImageCropper = (props) => {

    const {imageUri, imagePath, brandId} = props;
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);
    const [croppedAreaPixels, setcroppedAreaPixels] = useState({});
    const [defaultImage, setDefaultImage] = useState(false);

    const aspect = 5 / 3;

    const onCropComplete = (area, croppedPixels) => {
        setcroppedAreaPixels(croppedPixels);
    }

    const handleCheck = (event) => {
        event.target.value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setDefaultImage(event.target.value);
    }

    const onSave = () => {
        return new Promise(function (resolve, reject) {
            BrandImageApi.crop({imagePath, ...croppedAreaPixels, defaultImage, brandId}).then(response => {
                const res = response.data;
                if (res && res.success === 1) {
                    resolve({error: false, message: "The image successfully sent for approval"});
                    window.location.reload();
                } else {
                    reject(res.message);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    useEffect(() => {
        props.popup.activeTab == 3 && props.primaryAction({primaryBtn: {action: () => onSave() }});
    }, [props.popup.activeTab, croppedAreaPixels, defaultImage]);

    return (
        <>
            <div className="crop-container">
                <Cropper
                    image = {imageUri}
                    crop = {crop}
                    zoom = {zoom}
                    zoomWithScroll = {true}
                    aspect = {aspect}
                    onCropChange = {onCropChange}
                    onCropComplete = {onCropComplete}
                    onZoomChange = {onZoomChange}
                />
            </div>

            <div className="controls">
                <Slider
                    value = {zoom}
                    min = {1}
                    max = {3}
                    step = {0.1}
                    aria-labelledby = "Zoom"
                    onChange = {(e, zoomValue) => onZoomChange(zoomValue)}
                />
            </div>

            <Label check >
                <Input
                    type="checkbox"
                    name="defaultImageCheck"
                    onChange={handleCheck}
                />
                &nbsp;&nbsp;Set new image as the default image after the approval
            </Label>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        primaryAction: (metadata) => { dispatch(primaryAction(metadata)) }
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper);
