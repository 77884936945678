import React, { useEffect, useState } from 'react';
import BlockTitle from '../../common/FormTitle';
import OrderApi from '../../../api/Order';
import './OrderDetailPage.scss';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import PriceFormat from "../../../utils/PriceFormat";
import { getDateTimeStr } from "../../../utils/helper";

const OrderDetailPage = (props) => {

    const [order, setOrder] = useState({});
    const [message, setMessage] = useState('');
    const history = useHistory();
    const orderItems = order.orderItems || [];
    const customerId = order.customerId;
    const [deliveredImages, setDeliveredImages] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        OrderApi.get(props.match.params.orderId).then(response => {
            let data = response.data;
            if (data.customerId !== undefined) {
                setOrder(data);
                if (data.status === 6) {
                    setMessage("The order did not complete due to a system issue. Please contact us for more information.")
                } else if (data.status === 8) {
                    setMessage("The order did not complete due to payment failure. Please check your Paypal account.")
                }
            } else {
                handleBack();
            }
        }).catch(err => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        const images = orderItems.map((item) => item.brandImage.bwImagePath);
        setDeliveredImages(images);
    }, [orderItems]);

    const handleBack = () => {
        history.push("/my-orders");
    }

    return (
        <div className="order-details-container">
            <h1>Order #{order.orderNumber} <span className="order-status">{order.externalStatus}</span>
                <span className="order-date">Order Date: {getDateTimeStr(order.createdAt)}</span> </h1>

            <p className="text-danger">{message}</p>

            <table className="table table-borderless order-items-table">
                <thead>
                    <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Delivered brand image</th>
                        <th className="text-end" scope="col">Price</th>
                        <th className="text-end" scope="col">Qty</th>
                        <th className="text-end" scope="col">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((item, key) => {

                        let itemPrice;
                        let subTotal;
                        let currentBrandImagePath = item.brandImage.bwImagePath;

                        if (item.product.sellingMethod === "AUCTION") {
                            itemPrice = item.product.productAuction.bids.find(bid => bid.customerId === customerId).bidValue;
                            subTotal = itemPrice;
                        } else {
                            itemPrice = item.price;
                            subTotal = item.qtyOrdered * item.price;
                        }

                        return <tr key={key}>
                            <td><a href={`/product/${item.product.id}`} target='_blank'>{item.product.name}</a></td>
                            <td><img src={currentBrandImagePath} height="60" alt='product image' /><br /></td>
                            <td className="text-end"><PriceFormat price={itemPrice} /></td>
                            <td className="text-end">{item.qtyOrdered}</td>
                            <td className="text-end"><PriceFormat price={subTotal} /></td>
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th className="text-end" colSpan="3">Grand Total</th>
                        <th className="text-end"><PriceFormat price={order.totalAmount} /></th>
                    </tr>
                </tfoot>
            </table>

            <div id="order-other-info">
                <BlockTitle>Order Information</BlockTitle>
                <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <th>Billing Address</th>
                            <th>Payment Method</th>
                        </tr>
                        <tr>
                            <td>{order.billingAddress}</td>
                            <td>{order.paymentMethod}</td>
                        </tr>
                        <tr>
                            <th>Delivered images</th>
                            {orderItems.map((item, key) => {
                                if (item.product.sellingMethod === "AUCTION") {
                                    if (item.product.productAuction.numOfWinners > 1) {
                                        return <th>Winning position</th>
                                    }
                                }
                            })}
                        </tr>
                        <tr>
                            <td className="brand-image-td">
                                {deliveredImages.map((image, index) => (
                                    <div key={index} className="image-container">
                                        <img className="brand-image" src={image} alt={`Delivered image ${index}`} />
                                    </div>
                                ))}
                            </td>


                            {orderItems.map((item, key) => {
                                if (item.product.sellingMethod === "AUCTION") {
                                    if (item.product.productAuction.numOfWinners > 1) {
                                        const position = item.product.productAuction.bids.find(bid => bid.customerId === customerId).winningPosition;
                                        return <tr key={key}>
                                            <td>{position}</td>
                                        </tr>
                                    }
                                }
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            <Button variant="primary" type="button" onClick={handleBack}>Back to My Orders</Button>
        </div>
    );
}

export default OrderDetailPage;