import initialState from './InitialState';
import storage from '../../utils/storage';
import { NotificationManager } from 'react-notifications';
import { ADD_TO_CART,REMOVE_ITEM, CHANGE_QTY, CHECKOUT_SUCCESS, CHECKOUT_ERROR, RELOAD_CART } from '../../actions/ActionTypes'

const CartReducer = (state = initialState.cart, action) => {

    let newstate = {};

    const updateSession = (cart) => {
        storage.setData('cart', JSON.stringify(cart));
    }

    switch(action.type) {

        case RELOAD_CART:
            let itemsInSession = action.item;
            return itemsInSession;

        case ADD_TO_CART:

            let inputItem = action.item;
            let qty = action.qty || 1;
            let totalQty = 0;
            //check if the action id exists in the addedItems
            let exists = state.items.find(item => (inputItem.id === item.id) && (inputItem.defaultImageId == item.defaultImageId));
            
            state.items.forEach(item => {
                if (item.id === inputItem.id && inputItem.defaultImageId !== item.defaultImageId) {
                    totalQty += item.cartQty;
                    item.availableQty -= qty;
                }
            });

            if (exists) {
                exists.cartQty += qty;
                newstate = {
                    ...state,
                    total: state.total + (qty * inputItem.price),
                };
            } else {
                //inputItem.qty = qty;
                let newTotal = state.total + (qty * inputItem.price);
                let newCount = state.count + 1;
                inputItem.cartQty = qty;
                inputItem.availableQty -= totalQty;

                newstate =  {
                    ...state,
                    items: [...state.items, inputItem],
                    total : newTotal,
                    count: newCount,
                }
            }

            updateSession(newstate)
            NotificationManager.success('Item added to cart!');
            return newstate;

        case REMOVE_ITEM:
            let itemId = action.id;
            let defaultImageId = action.defaultImageId;
            let itemToRemove = state.items.find(item => itemId === item.id && defaultImageId === item.defaultImageId)
            let rest = state.items.filter(item => itemId !== item.id || defaultImageId !== item.defaultImageId);
            let newTotal = state.total - (itemToRemove.price * itemToRemove.cartQty);
            let newCount = state.count - 1;

            rest.forEach(item => {
                if (item.id === itemToRemove.id) {
                    item.availableQty += itemToRemove.cartQty;
                }
            });

            if (newTotal < 0) {
                newTotal = 0;
            }

            if (newCount < 0) {
                newCount = 0;
            }

            newstate =  {
                ...state,
                items: rest,
                total: newTotal,
                count: newCount,
            }

            let sessionState = newCount > 0 ? newstate : initialState.cart;
            updateSession(sessionState);
            return newstate;

        case CHANGE_QTY:

            let addedItem = state.items.find(item => action.id === item.id && action.defaultImageId === item.defaultImageId )
            let newQty = action.qty || 0;

            if (newQty < 0) {
                newQty = 0;
            }

            state.items.forEach(item => {
                if (item.id === addedItem.id && action.defaultImageId !== item.defaultImageId) {
                    item.availableQty += (addedItem.cartQty - newQty);
                }
            });

            let total = state.total - (addedItem.price * addedItem.cartQty) + (addedItem.price * newQty);
            addedItem.cartQty = newQty;

            newstate =  {
                ...state,
                total: total,
            }

            updateSession(newstate);
            return newstate;

        case CHECKOUT_SUCCESS:

            let initialCart = initialState.cart;

            //reset cart sesssion
            updateSession(initialCart);

            newstate =  {
                ...initialCart,
                checkout: {
                    success: action.success,
                    orderId: action.data.id,
                    orderIdFormatted: action.data.orderIdFormatted
                }
            }

            return newstate;

        case CHECKOUT_ERROR:

            let inputErroritems = action.data || [];
            let errorCartItems = [];

            inputErroritems.map((inputErrorItem, key) => {
                let errorCartItem =  state.items.find(cartItem => inputErrorItem.productId === cartItem.id);
                errorCartItem.error =  1;
                errorCartItem.message = inputErrorItem.message;
                errorCartItems.push(errorCartItem);
            });

            newstate =  {
                ...state,
            }

            return newstate;

        default:
            return state;
    }
}

export default CartReducer;
