import React, {useEffect, useState} from 'react';
import FormTitle from '../../common/FormTitle';
import AccountSettingsAPI from '../../../api/AccountSettings';
import {Container,Row,Col} from "reactstrap";
import {Link} from "react-router-dom";
import Paypal from "../../../api/Paypal";
import Common from "../../../api/Common";
import BlockUi from "react-block-ui";
import {ACCOUNT_COMPLETION_STEPS_COUNT} from '../../../constants';
import { Button } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";

const Dashboard  = (props) => {

    const [formData, setFormData] = useState({});
    const [paypalEmail, setPaypalEmail] = useState("");
    const [payPalStyle, setPayPalStyle] = useState({color:'inherit'});
    const [finalStatus, setFinalStatus] = useState(0);
    const [stepsCount, setStepsCount] = useState(0);
    const [isLoading,setIsLoading] = useState(true);

    useEffect(() => {
        loadCustomerInfo();
        window.scrollTo(0, 0);
    }, []);

    const generateCustomerAddress = (data) => {
        return data.customerAddress.addressLineOne + ', '
        + data.customerAddress.addressLineTwo + ', '
        + data.customerAddress.city + ', '
        + data.customerAddress.state + ', '
        + data.customerAddress.postalCode + ', '
        + data.customerAddress.country.countryName;
    }

    const generateAuthorizedContactName = (data) => {
        return data.authorizedContactInformation.title + '. '
        + data.authorizedContactInformation.firstName + ' '
        + data.authorizedContactInformation.lastName;
    }

    const generateAccountStatus = (data) => {
        let stepCount = 0;
        if(data.status === "ACTIVE") {
            stepCount ++;
        }
        if(data.defaultImage !== null) {
            stepCount ++;
        }
        if(data.paymentMethod !== null) {
            stepCount ++;
        }
        if(data.customerAddress !== null) {
            stepCount ++;
        }
        if(data.emailVerificationStatus === "VERIFIED") {
            stepCount ++;
        }
        setStepsCount(stepCount);
        if(stepCount === ACCOUNT_COMPLETION_STEPS_COUNT) {
            setFinalStatus(1);
            return "Completed"
        } else {
            setFinalStatus(0);
            return <><b>Incomplete</b> ({stepCount}/{ACCOUNT_COMPLETION_STEPS_COUNT} Steps Completed)</>
        }
    }

    const loadCustomerInfo = () => {

        setIsLoading(true);

        let paymentTerms;

        Common.paymentTerms().then(response => {
            paymentTerms = response.data;
        }).catch(err => {
            console.log(err)
        })

        AccountSettingsAPI.getCustomerData().then(response => {
            const data = response.data || {};
            let paymentTermLabel = "Not Added";

            if (data.paymentTermId > 0 && paymentTerms !== undefined) {
                paymentTerms.map((item) => {
                    if(item.id === data.paymentTermId) {
                        paymentTermLabel = item.label;
                    }
                })
            }

            if (data) {
                let info = {
                    companyName: data.companyName,
                    brandName: data.brands.map(brand => brand.brandName),
                    industry: (data.industry && data.industry.name) || "Not Added",
                    paymentMethod: (data.paymentMethod && data.paymentMethod.name) || "Not Added",
                    paymentTermId: data.paymentTermId || 0,
                    paymentTermLabel: paymentTermLabel,
                    address: (data.customerAddress && generateCustomerAddress(data)) || "Not Added",
                    telephone: (data.customerAddress && data.customerAddress.telephone) || "Not Added",
                    name: (data.authorizedContactInformation && generateAuthorizedContactName(data)) || "Not Added",
                    designation: (data.authorizedContactInformation && data.authorizedContactInformation.designation) || "Not Added",
                    contact: (data.authorizedContactInformation && data.authorizedContactInformation.contactNo) || "Not Added",
                    status: generateAccountStatus(data),
                    email: data.email,
                }
                if (data.paymentMethod && data.paymentMethod.id === 1) {
                    Paypal.getPaypalInfo().then(res => {
                        if(res.data.success === 0) {
                            setPaypalEmail("Couldn't get PayPal information");
                            setPayPalStyle({color:"red"})
                        } else {
                            setPaypalEmail(res.data);
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
                setFormData({...formData, ...info});
            }
            setIsLoading(false);
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <BlockUi className="block-ui" tag="div" blocking={isLoading} message={"Loading"}>
            <Container>
                <Row>
                    <Col className={finalStatus === 1 ? "" : stepsCount === 0 ? "alert-danger" : "alert-warning"} sm={12} md={12} lg={6} xl={6}>
                        <br/>
                            <FormTitle>
                                <div className="float-start">Account Settings</div>
                                <div className="float-end">
                                    <Link to="/account-settings/settings-and-preferences">
                                        <CiEdit />
                                    </Link>
                                </div>
                            </FormTitle>  
                        <div className="">
                            <p>Account Status : {formData.status}</p>
                        </div>
                        <div className="">
                            <p>Primary Email : {formData.email}</p>
                        </div>
                        <div className="">
                            <p>Alternative Email : {"Not Added"}</p>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <br/>
                        <FormTitle>
                            Payments
                            <div className="float-end">
                                <Link to="/account-settings/settings-and-preferences">
                                    <CiEdit />
                                </Link>
                            </div>
                        </FormTitle>
                        <div className="">
                            <p>Payment Method : {formData.paymentMethod}</p>
                        </div>
                        {formData.paymentMethod === "PAYPAL" ?
                            <div className="">
                                <p style={payPalStyle}>PayPal Account : {paypalEmail}</p>
                            </div> :
                            formData.paymentMethod === "Not Added" ?
                                <></> :
                                <div className="">
                                    <p>Payment Term : {formData.paymentTermLabel}</p>
                                </div>
                        }
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <br/>
                        <FormTitle>
                            Business Information
                            <div className="float-end">
                                <Link to="/account-settings/account-information">
                                    <CiEdit />
                                </Link>
                            </div>
                        </FormTitle>
                        <div className="">
                            <p>Brand Name : {formData.brandName}</p>
                        </div>
                        <div className="">
                            <p>Company Name : {formData.companyName}</p>
                        </div>
                        <div className="">
                            <p>Industry : {formData.industry}</p>
                        </div>
                        <div className="">
                            <p>Billing Address : {formData.address}</p>
                        </div>
                        <div className="">
                            <p>Telephone : {formData.telephone}</p>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <br/>
                        <FormTitle>
                                System Preferences
                            <div className="float-end">
                                <Link to={{
                                    pathname: "/account-settings/settings-and-preferences",
                                    activeTab: 2
                                }}>
                                    <CiEdit />
                                </Link>
                            </div>
                        </FormTitle>

                        {/*don't remove this commented part!*/}

                        {/*<div className="">*/}
                        {/*    <p>Language : {"English (US)"}</p>*/}
                        {/*</div>*/}
                        <div className="">
                            <p>Timezone : {"System Default"}</p>
                        </div>
                        <div className="">
                            <p>Display Currency : {"United States Dollar ($)"}</p>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <br/>
                        <FormTitle>
                            Authorized Contact Information
                            <div className="float-end">
                                <Link to={{
                                    pathname: "/account-settings/account-information",
                                    activeTab: 2
                                }}>
                                    <CiEdit />
                                </Link>
                            </div>
                        </FormTitle>
                        <div className="">
                            <p>Name : {formData.name}</p>
                        </div>
                        <div className="">
                            <p>Designation : {formData.designation}</p>
                        </div>
                        <div className="">
                            <p>Contact No : {formData.contact}</p>
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <br/>
                        <FormTitle>
                            Notification Preferences
                            <div className="float-end">
                                <Link to="/account-settings/notifications-preferences">
                                    <CiEdit />
                                </Link>
                            </div>
                            </FormTitle>
                        <div className="">
                            <p>Order Confirmation : {"On"}</p>
                        </div>
                        <div className="">
                            <p>Image Approval/Refusal : {"On"}</p>
                        </div>
                        <div className="">
                            <p>Newsletter Subscription : {"Off"}</p>
                        </div>
                        <div className="">
                            <p>Security Notifications : {"Off"}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BlockUi>
    )
}

export default Dashboard;